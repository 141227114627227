/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            loadMore: function() {
                var $grid = $('.js--home-grid').isotope({});

                var page = $('.js--home-grid').data('page');
                $.ajax({
                    type: "POST",
                    dataType: 'html',
                    url: '/wp-admin/admin-ajax.php',
                    data: {
                        'action': 'loadmore_home',
                        'page': page
                    },
                    success: function(data) {
                        // This outputs the result of the ajax request

                        var $items = $(data);
                        // append items to grid
                        $grid.append($items)
                        // add and lay out newly appended items
                        .isotope('appended', $items);

                        $('.js--home-grid').data('page', page + 1);
                          Waypoint.refreshAll();

                    },
                    error: function(errorThrown) {
                        console.log(errorThrown);
                    }


                });
            },
            init: function() {

              var home = this;

                var $grid = $('.js--home-grid').isotope({});

                $('.js--home-loadmore').click(function(e) {
                    e.preventDefault();

                var page = $('.js--home-grid').data('page');

                $.ajax({

                type: "POST",
                dataType: 'html',
                url: '/wp-admin/admin-ajax.php',
                data: {

                   'action':'loadmore_home',
                    'page' : page

                },

                success:function(data) {

                // This outputs the result of the ajax request
                var $items = $(data);

                // append items to grid
                $grid.append( $items )

               // add and lay out newly appended items
               .isotope( 'appended', $items );

                $('.js--home-grid').data('page', page+1);

                },

                error: function(errorThrown){

                console.log(errorThrown);

                    }

                 });   

                })

                var waypoints = $('.js--home-loadmore').waypoint({
                    handler: function(direction) {
                        if (direction == 'down') {
                            //                        alert('helllo');
                            //home.loadMore();

                        }
                    },
                    offset: '100%'
                })

                $('.js--burger-link').click(function(e) {
                    e.preventDefault();
                    $(".js--menu-mobile-wrapper").fadeIn();
                })

                $('.js--burger-close').click(function(e) {
                    e.preventDefault();
                    $(".js--menu-mobile-wrapper").fadeOut();
                })

                 $('.js--search-link').click(function(e) {
                    e.preventDefault();
                    $(".js--search-wrapper").fadeIn();
                })


    var sticky = new Waypoint.Sticky({
      element: $('.menu')[0]
    })


            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
                 var initHomeSlider = function() {
                    if ($(".body").hasClass('home')) {
                        $('.js--main-gallery').flickity({
                            imagesLoaded: true,
                            setGallerySize: false,
                            wrapAround: true,
                            autoPlay: 6000,
                            pauseAutoPlayOnHover: false
                        });
                    }
                    var $gallery = $('.js--main-gallery').flickity();

                    $gallery.on('settle click cellSelect', function() {
                        $('.js--hp-carousel__number__index').html($gallery.data('flickity').selectedIndex + 1);
                        $('.js--hp-carousel__number__total').html($gallery.data('flickity').cells.length);
                    })

                }
                
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // SUBMENUS

    sfHover = function() {
        var sfEls = document.getElementById("nav-primary").getElementsByTagName("LI");
        for (var i = 0; i < sfEls.length; i++) {
            sfEls[i].onmouseover = function() {
                this.className += " sfhover";
            }
            sfEls[i].onmouseout = function() {
                this.className = this.className.replace(new RegExp(" sfhover\\b"), "");
            }
        }
    }

    if (window.attachEvent) window.attachEvent("onload", sfHover);



    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

